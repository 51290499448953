import { get, post } from '@/common/rest';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { getCognitoConfig } from '@qmu/common/creds/cognito-creds';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { TenantConfig } from '@qmu/common/dto/TenentConfigDtos';
import { SingleUserDataResponse, TenantUserAppendToList, TenantUserList, TenantUserListMapAppendToList } from '@qmu/common/dto/userDataDto';
import { UserEventType, UserType } from '@qmu/common/enum/UserTypes';
import { ParsedJwt, parseJwt } from '@qmu/common/format/jwt';
import { findUserGroup } from '@qmu/common/user/findUserGroup';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { UserListHandlerPayload } from './admin/dto/handleLoadActivityDto';
import { getSubscriptionStatus } from './common/notificationServices';
import { getTenantConfigForced } from './common/tenantConfigHandler';
import { updateUserProfileInfo } from './common/userInfo';
import { UserPoolData } from './common/userPoolData';
import { fetchMimirData } from './common/xmpUtils';
import store, { ActionsTypes, GettersTypes } from './store';

const isLocalHost: boolean = window.location.hostname === 'localhost';
const BASE_URL: string = isLocalHost ? `http://${window.location.hostname}:3000/staging` : `https://${window.location.hostname}`;
const SERVICE_DOCUMENTS_URL: string = '/api/v1 ';

/**
 * Gets client configuration data from service document, then converts those
 * data into aws auth configuration data object and configures Amplify Auth.
 */
const configureAuth = (): void => {
  const awsConfig = getCognitoConfig(store.getters[GettersTypes.GET_CLIENT_CONFIGS]);
  Auth.configure(awsConfig);
};

/**
 * Confirm User data in vuex if not presenet in Vuex. If the user is not yet]
 * Authenticated we skip it.
 */
export const confirmUserDataInVuex = async (forced = false): Promise<void> => {
  const userData = store.getters[GettersTypes.GET_USER_JWT_TOKEN];
  if (!userData || forced) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const parsed: ParsedJwt = parseJwt(user.signInUserSession.idToken.jwtToken);
      store.dispatch(ActionsTypes.SET_JWT_ID_TOKEN, parsed);
    } catch (error) {
      // User is not yet authenticated
    }
  }
};

/**
 * Get tenant config from server, if doesnt exists, create default tenant config
 * and set the config in vuex. If the user is not authenticated yet, we skip it.
 */
export const confirmTenantConfigInVuex = async (forced = false): Promise<void> => {
  const tenantConfig = store.getters[GettersTypes.GET_TENANT_CONFIG];
  if (tenantConfig && !forced) return; // Must reset store after signout, else first tenant config will be used
  const tenantId = store.getters[GettersTypes.GET_TENANT_ID];
  if (!tenantId) return;
  try {
    const tenantConfig: TenantConfig = await getTenantConfigForced(tenantId);
    store.dispatch(ActionsTypes.SET_TENANT_CONFIG, tenantConfig);
  } catch (error) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Tenant config fetching failed', TOAST.ERROR);
  }
};

export const bootStrapper = async (): Promise<void> => {
  await setServiceDoc();
  await confirmUserDataInVuex(); // this need to call after load setServiceDoc
  const userData = store.getters[GettersTypes.GET_USER_JWT_TOKEN];
  if (isEmpty(userData)) return;
  await setUserGroup();
  if (store.getters[GettersTypes.GET_USER_GROUP] === UserType.READONLY_USER) return;
  fetchMimirData();
  await Promise.all([setEmailSubscriptionInfo(), confirmTenantConfigInVuex()]); // this need to load after confirmUserDataInVuex
};

const setServiceDoc = async (forced = false): Promise<void> => {
  const serviceDocs = store.getters[GettersTypes.GET_SERVICE_DOCS];

  if (!serviceDocs || forced) {
    let isError = false;
    try {
      const response = await axios.get(`${BASE_URL}${SERVICE_DOCUMENTS_URL}`);
      if (response.status === 200) {
        store.dispatch(ActionsTypes.SET_SERVICE_DOCS, response.data);
        configureAuth();
      } else {
        isError = true;
      }
    } catch (error) {
      isError = true;
    } finally {
      if (isError) EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to get service document', TOAST.ERROR);
    }
  }
};

export const setUserGroup = async (forced = false): Promise<void> => {
  const userInfo = store.getters[GettersTypes.GET_USER_GROUP];
  if (!userInfo || forced) {
    const userData = store.getters[GettersTypes.GET_USER_JWT_TOKEN] as ParsedJwt;
    const userPoolData = new UserPoolData();
    const groupList = await userPoolData.getGroupsOfUser(userData.payload['cognito:username']);
    store.dispatch(ActionsTypes.SET_USER_INFO, findUserGroup(groupList));
    await setTenantList();
    await updateUserInfo()
  }
};

export const setEmailSubscriptionInfo = async (): Promise<void> => {
  const subscriptionStatus = store.getters[GettersTypes.GET_SUBSCRIPTION_INFO];
  if (subscriptionStatus === undefined) {
    // value can be false, thats why checking with undefined
    const userId = store.getters[GettersTypes.GET_USER_ID];
    const tenantId = store.getters[GettersTypes.GET_TENANT_ID];
    try {
      const statusInfo = await getSubscriptionStatus(tenantId, userId);
      let subscriptionStatus;
      Object.keys(statusInfo).forEach(type => (subscriptionStatus = statusInfo[type as keyof typeof statusInfo]?.status));
      store.dispatch(ActionsTypes.SET_EMAIL_SUBSCRIPTION_INFO, subscriptionStatus);
    } catch (error) {
      store.dispatch(ActionsTypes.SET_EMAIL_SUBSCRIPTION_INFO, false);
    }
  }
};

export const updateUserInfo = async () => {
  const userId = store.getters[GettersTypes.GET_USER_ID];
  const tenantId = store.getters[GettersTypes.GET_TENANT_ID];
  if (!userId || tenantId !== 'dpa') return;
  try {
    const updateSpecificUserLink = store.getters[GettersTypes.GET_SERVICE_LINKS].updateSpecificUser;
    const payload = {
      userId,
      tenantId,
      eventType: UserEventType.UPDATE_PROFILE,
    };
    await updateUserProfileInfo(updateSpecificUserLink, payload);
  } catch (error) {
    //Failed to update user info
  }
};

export const setTenantList = async (forced = false) => {
  try {
    const userGroup = store.getters[GettersTypes.GET_USER_GROUP];
    if (userGroup === UserType.NORMAL_USER || userGroup === UserType.READONLY_USER) return;
    if (userGroup === UserType.SUPER_ADMIN) {
      const tenantListStore = store.getters[GettersTypes.GET_TENANT_LIST];
      if (!isEmpty(tenantListStore) && !forced) return;
      const url = store.getters[GettersTypes.GET_TENANT_ID_LIST_LINK];
      const response = await get(url);
      const tenantList = response?.data?.data?.tenantList;
      if (isEmpty(tenantList)) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'TenantList is empty', TOAST.ERROR);
        return;
      }
      store.dispatch(ActionsTypes.SET_TENANT_LIST, tenantList);
    }
    await setAllTenantUserToStore();
  } catch (error) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to load tenantList', TOAST.ERROR);
  }
};

export const setAllTenantUserToStore = async () => {
  const tenantUserList = store.getters[GettersTypes.GET_TENANT_USER_LIST] as TenantUserList;
  const userGroup = store.getters[GettersTypes.GET_USER_GROUP] as string;
  store.dispatch(ActionsTypes.SET_SELECTED_TENANAT, store.getters[GettersTypes.GET_TENANT_ID] as string);
  if (userGroup === UserType.READONLY_USER || userGroup === UserType.NORMAL_USER) return;
  const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const tenantList: string[] = userGroup === UserType.TENANT_ADMIN ? [store.getters[GettersTypes.GET_TENANT_ID] as string] : (store.getters[GettersTypes.GET_TENANT_LIST] as string[]);
  if (!isEmpty(tenantUserList) || isEmpty(tenantList) || !userGroup || !serviceLinks) return;
  try {
    const postRequestList = tenantList.map(tenant => {
      const params: UserListHandlerPayload = { tenantId: tenant, isAllUser: true, selectedGroup: '' };
      return post(serviceLinks.getUserList, { data: params });
    });
    const responseList = await Promise.all(postRequestList);
    for (const index in responseList) {
      const userList = responseList[index].data.data.results;
      if (isEmpty(userList)) {
        EventBus.$emit(EVENTS.SHOW_TOAST, `${tenantList[index]} userList is empty`, TOAST.ERROR);
        continue;
      }
      const makeTenantUserList: TenantUserAppendToList = { key: tenantList[index], data: userList };
      store.dispatch(ActionsTypes.SET_TENANT_USER_LIST, makeTenantUserList);
      storeDataMapForUserList(tenantList[index], userList);
    }
  } catch (error) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to load Tenant user list', TOAST.ERROR);
  }
};

export const storeDataMapForUserList = (tenant: string, userList: Array<SingleUserDataResponse>) => {
  storeTenantUserListEmailUserIdMap(tenant, userList);
  storeTenantUserListUserNameUserIdMap(tenant, userList);
  storeTenantUserLisUserIdEmailMap(tenant, userList);
};

export const storeTenantUserListEmailUserIdMap = async (tenant: string, userList: Array<SingleUserDataResponse>) => {
  for (const user of userList) {
    const payload: TenantUserListMapAppendToList = { key1: tenant, key2: user.email || '', data: user.id || '' };
    store.dispatch(ActionsTypes.SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP, payload);
  }
};

export const storeTenantUserListUserNameUserIdMap = async (tenant: string, userList: Array<SingleUserDataResponse>) => {
  for (const user of userList) {
    const payload: TenantUserListMapAppendToList = { key1: tenant, key2: user.userName || '', data: user.id || '' };
    store.dispatch(ActionsTypes.SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP, payload);
  }
};

export const storeTenantUserLisUserIdEmailMap = async (tenant: string, userList: Array<SingleUserDataResponse>) => {
  for (const user of userList) {
    const payload: TenantUserListMapAppendToList = { key1: tenant, key2: user.id || '', data: user.email || '' };
    store.dispatch(ActionsTypes.SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP, payload);
  }
};
