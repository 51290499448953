var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"dense":"","color":"bgSecondary","outlined":""}},[_c('v-row',{staticClass:"mt-2 ml-2"},[_c('v-col',{attrs:{"cols":"3"}},[_c('UserList',{attrs:{"isClear":_vm.triggerClear,"deletedUserName":_vm.reactiveDeletedUser,"label":_vm.SelectBoxLabel.LAST_NAME},on:{"triggerSelectedUser":function($event){return _vm.setSelectedName($event)}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('UserList',{attrs:{"isClear":_vm.triggerClear,"deletedUserName":_vm.reactiveDeletedUser,"label":_vm.SelectBoxLabel.EmailOrUserID},on:{"triggerSelectedUser":function($event){return _vm.setSelected($event)}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"background-color":"textFieldPrimary","dense":"","outlined":"","items":_vm.AllGroups,"label":"Groups","placeholder":"Select"},on:{"change":_vm.changeSelected},model:{value:(_vm.filterSelectedGroup),callback:function ($$v) {_vm.filterSelectedGroup=$$v},expression:"filterSelectedGroup"}})],1),_c('v-spacer'),_c('v-row',{staticClass:"mt-4 mr-6"},[_c('v-spacer'),_c('FilterBtn',{on:{"triggerApplyFilterBtn":_vm.applyFiltering}})],1)],1)],1),_c('v-row',{staticClass:"my-1 mr-4"},[_c('v-spacer'),_c('ClearFilter',{on:{"triggerClearFilter":_vm.clearFiltering}})],1),_c('v-card',{staticClass:"mt-5",attrs:{"outlined":"","color":"bgSecondary","loading":_vm.isUserListLoading? 'cardLoadingColor': 'transparent'}},[_c('v-data-table',{staticClass:"custom-table-color",attrs:{"dense":"","headers":_vm.getHeaders(),"items":_vm.users,"item-key":"email","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.filteredGroups(item.groups)),function(group){return _c('div',{key:group},[_c('td',[_vm._v(_vm._s(group))])])})}},{key:"item.groupEdit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getUserInfo(item, _vm.operationType.UPDATE_GROUP)}}},[_c('v-icon',{attrs:{"center":""}},[_vm._v("mdi-account-edit")])],1)]}},{key:"item.userDelete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.isUserDeletable(item)},on:{"click":function($event){return _vm.getUserInfo(item, _vm.operationType.DELETE_USER)}}},[_c('v-icon',{attrs:{"center":""}},[_vm._v("mdi-delete-circle")])],1)]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.pageNumber <= 1 || _vm.isUserListLoading},on:{"click":_vm.previousPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_vm._v(" page: "+_vm._s(_vm.pageNumber)+" "),_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEnd || _vm.isUserListLoading},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }