import { UserEventType } from '@qmu/common/enum/UserTypes';
import { post } from './rest';
import { SpecificUserPayload } from '@qmu/common/dto/userDataDto';

export type SpecificUserInfoUpdatePayload = SpecificUserPayload & {
  eventType: UserEventType;
};

export const updateUserProfileInfo = async (link: string, payload: SpecificUserInfoUpdatePayload): Promise<void> => {
  try {
    await post(link, { data: payload });
  } catch (err) {
    //
  }
};
