











































































import store, { GettersTypes } from '@/store';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import { UserType } from '@qmu/common/enum/UserTypes';
import { Component, Vue } from 'vue-property-decorator';
import NotificationSubscription from '../components/NotificationSubscription.vue';
import { DOCUMENTATION_LINK } from '../utils/documentation';

@Component({
  components: {
    NotificationSubscription,
  },
})
export default class Settings extends Vue {
  subscriptionStatus = false;
  userEmail = store.getters[GettersTypes.GET_USER_EMAIL] ?? 'not found';
  userId = store.getters[GettersTypes.GET_USER_ID] ?? 'not found';
  tenantId = store.getters[GettersTypes.GET_TENANT_ID] ?? 'not found';
  firstName = store.getters[GettersTypes.GET_USER_FIRST_NAME] ?? '' ;
  lastName = store.getters[GettersTypes.GET_USER_LAST_NAME] ?? '';
  user = UserType;
  documentationLink = DOCUMENTATION_LINK;

  get userGroup() {
    return store.getters[GettersTypes.GET_USER_GROUP] as UserInfo;
  }

  setSubscriptionStatus(status: boolean) {
    this.subscriptionStatus = status;
  }
}
