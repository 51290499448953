





































































import { UserOperationType } from '@/common/enums/userOperationEnums';
import { post } from '@/common/rest';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { SelectBoxLabel } from '@/utils/enum/label';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import { BasicPayloadFileActivity } from '@qmu/common/dto/activityModels';
import { ItemState } from '@qmu/common/dto/itemModels';
import { SingleUserDataResponse, TenantUserList } from '@qmu/common/dto/userDataDto';
import { NONE, UserType, allAvailableGroups } from '@qmu/common/enum/UserTypes';
import { isEmpty } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserListHandlerPayload } from '../dto/handleLoadActivityDto';
import { LoadUserEmitDto } from '../dto/loadUserDto';
import ClearFilter from './activityFields/ClearFilter.vue';
import FilterBtn from './activityFields/FilterBtn.vue';
import UserList from './activityFields/UserList.vue';


@Component({
  components: {
    UserList,
    FilterBtn,
    ClearFilter,
  },
})
export default class LoadUser extends Vue {
  @Prop({ default: false }) showItemState!: boolean;
  @Prop({ default: false }) showAllUserActivity!: boolean;
  @Prop({ default: '' }) deletedUserName!: string;
  tenantList: string[] | null = null;
  selectedStatus = '';
  isUserListLoading = false;
  usersList: Array<SingleUserDataResponse> = [];
  itemStateList = Object.values(ItemState);
  tenantId = '';
  isEnd = true;
  pageNumber = 0;
  operationType = UserOperationType;
  isTriggerClear = false;
  filterSelectedUser = '';
  filterSelectedGroup = '';
  filterSelectedName = '';
  SelectBoxLabel=SelectBoxLabel

  tenantListRules = [(v: string) => !!v || 'Tenant is required'];
  statusListRules = [(v: string) => !!v || 'Status is required'];

  changeSelected() {
    if (isEmpty(this.filterSelectedGroup)) this.filterSelectedGroup = '';
  }

  get AllGroups() {
    return allAvailableGroups().concat(NONE);
  }

  isUserDeletable(item: SingleUserDataResponse) {
    if ((store.getters[GettersTypes.GET_USER_ID] as string) === item.id) return true;
    if (this.userGroup === UserType.TENANT_ADMIN && item.groups?.includes(UserType.SUPER_ADMIN)) return true;
    return false;
  }

  getHeaders() {
    return [
      { text: 'Name', value: 'fullName', align: 'left', width: '33%' },
      { text: 'Email', value: 'email', align: 'left' },
      { text: 'Groups', value: 'groups', align: 'left', sortable: false },
      { text: 'Edit group', value: 'groupEdit', align: 'center', sortable: false },
      ...(this.userGroup === this.userType.SUPER_ADMIN || this.userGroup === this.userType.TENANT_ADMIN ? [{ text: 'Delete user', value: 'userDelete', align: 'center', sortable: false }] : []),
    ];
  }
  get reactiveDeletedUser() {
    return this.deletedUserName;
  }

  set reactiveDeletedUser(value: string) {
    this.deletedUserName = value;
  }

  @Watch('deletedUserName')
  removedEntryOfUser() {
    if (!this.reactiveDeletedUser) return;
    const data = this.usersList.find(user => user.userName === this.reactiveDeletedUser);
    if (!data) return;
    const index = this.usersList.indexOf(data);
    if (index === -1) return;
    this.usersList.splice(index, 1);
    this.filterSelectedUser = '';
    this.filterSelectedGroup = '';
    this.filterSelectedName = '';
  }

  get users() {
    return this.usersList;
  }

  get triggerClear() {
    return this.isTriggerClear;
  }

  setSelected(selectedUser: string) {
    this.filterSelectedUser = selectedUser;
  }

  setSelectedName(selectedName: string) {
    this.filterSelectedName = selectedName;
  }

  applyFiltering() {
    const selectedTenantId = store.getters[GettersTypes.GET_SELECTED_TENANT];
    this.pageNumber = 1
    this.isEnd = true;
    if(!selectedTenantId){
      this.usersList = []
      return
    }
    const selectedTenantUsers = this.getTenantUsers(this.tenantId)

    const filteredNameUsers = this.getFilteredUsersByName(selectedTenantUsers)
    const filteredGroupUsers = this.getFilteredUsersByGroup();
    const filteredUserIdUsers = this.getFilteredUsersByUserId(selectedTenantUsers);
    this.usersList = [...new Set([...filteredNameUsers,...filteredGroupUsers,...filteredUserIdUsers])]

  }

  getFilteredUsersByName(selectedTenantUsers: SingleUserDataResponse[]){
    if(!this.filterSelectedName) return []
    return selectedTenantUsers.filter(user => user.lastName === this.filterSelectedName);
  }

  getFilteredUsersByUserId(selectedTenantUsers: SingleUserDataResponse[]) {
    if(!this.filterSelectedUser) return []
    const userId = this.filterSelectedUser
    return selectedTenantUsers.filter(user => user.id === userId || user.email === userId || user.userName === userId)
  }

  clearFiltering() {
    this.isTriggerClear = !this.isTriggerClear;
    this.filterSelectedUser = '';
    this.filterSelectedName = '';
    this.filterSelectedGroup = '';
    this.applyFiltering();
  }

  filteredGroups(groups: string[]): string[] {
    return allAvailableGroups().filter(group => {
      return groups.includes(group);
    });
  }

  get selectedTenant() {
    return store.getters[GettersTypes.GET_SELECTED_TENANT];
  }

  set selectedTenant(value: string | null) {
    store.dispatch(ActionsTypes.SET_SELECTED_TENANAT, value);
  }

  @Watch('selectedTenant')
  async onDataChanged() {
    this.pageNumber = 1;
    this.isEnd = true;
    this.usersList = this.getFilteredUsersByGroup();
    if(this.defaultLimit < this.usersList.length) this.isEnd = false;
  }

  async created() {
    this.isUserListLoading = true;
    if (this.selectedTenant) this.tenantId = this.selectedTenant;
    const selectedTenantUsers = this.getTenantUsers(this.tenantId)

    if(selectedTenantUsers.length){
      this.usersList = selectedTenantUsers.slice(0,this.defaultLimit)
      this.pageNumber = 1
    }

    if(this.defaultLimit < selectedTenantUsers.length) this.isEnd = false;
    this.isUserListLoading = false;
  }

  get defaultLimit() {
    return store.getters[GettersTypes.GET_TENANT_CONFIG_MAX_HISTORY];
  }

  get userType() {
    return UserType;
  }

  get userGroup() {
    return store.getters[GettersTypes.GET_USER_GROUP] as UserInfo;
  }

  getFilteredUsersByGroup() {
    if (!this.selectedTenant) return [];
    this.tenantId = this.selectedTenant;
    const selectedTenantUsers = this.getTenantUsers(this.tenantId)

    if(!this.filterSelectedGroup && !this.filterSelectedUser && !this.filterSelectedName){
      if(this.defaultLimit < selectedTenantUsers.length) this.isEnd = false;
      return selectedTenantUsers.slice(0,this.defaultLimit)
    }

    if (this.filterSelectedGroup === NONE){
      return selectedTenantUsers.filter(user => {
        if(user.groups && !user.groups.some(group => allAvailableGroups().includes(group))){
          return true
        }
        return false
      })
    }

    return selectedTenantUsers.filter(user => user.groups?.includes(this.filterSelectedGroup))
  }

  async previousPage() {
    const end = (this.pageNumber - 1) * this.defaultLimit
    const start = end - this.defaultLimit
    this.handleLoad(start,end, false)
  }

  async nextPage() {
    const start = this.pageNumber * this.defaultLimit
    const end = start + this.defaultLimit
    this.handleLoad(start,end, true)
  }

  handleLoad(start:number,end:number,isIncreasePageNumber:boolean):void{
    const selectedTenantUsers = this.getTenantUsers(this.tenantId)

    if(!selectedTenantUsers.length) {
      this.usersList = []
      this.pageNumber = 0
      this.isEnd = true
      return
    }

    this.usersList = selectedTenantUsers.slice(start,end)
    end < selectedTenantUsers.length ? this.isEnd = false : this.isEnd = true;
    isIncreasePageNumber ? this.pageNumber++ : this.pageNumber--
  }

  getTenantUsers (tenant:string):SingleUserDataResponse[]{
    const allTenantUsers = store.getters[GettersTypes.GET_TENANT_USER_LIST] as TenantUserList;
    return allTenantUsers[tenant] ?? []
  }

  async getUserListFromApi(params: UserListHandlerPayload) {
    const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    const response = await post(serviceLinks.getUserList, { data: params });
    return response;
  }

  async getUserActivity(id: string) {
    if (!this.selectedTenant) return;
    const dataParams: BasicPayloadFileActivity = {
      userId: id,
      tenantId: this.selectedTenant,
      itemState: this.selectedStatus,
    };
    this.$emit('triggerUserInfo', dataParams);
  }

  async getUserInfo(user: SingleUserDataResponse, operationType: UserOperationType) {
    if (user.id) this.getUserActivity(user.id);
    const emitPayload: LoadUserEmitDto = { userData: user, tenantId: this.tenantId, operationType };
    this.$emit('userInfo', emitPayload);
  }

  getAllUserActivity() {
    const isTenantFormValidated = (this.$refs.tenantForm as Vue & { validate: () => boolean }).validate();
    const isStatusFormValidated = this.showItemState ? (this.$refs.statusForm as Vue & { validate: () => boolean }).validate() : false;
    if (!isTenantFormValidated && !isStatusFormValidated) return;
    if (!this.selectedTenant || (!this.selectedStatus && this.showItemState)) return;
    const dataParams: BasicPayloadFileActivity = {
      tenantId: this.selectedTenant,
      itemState: this.selectedStatus,
    };
    this.$emit('triggerAllUserActivity', dataParams);
  }

  clearUserList() {
    this.usersList = [];
    this.selectedTenant = null;
    this.isUserListLoading = false;
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Clear Data!', TOAST.WARNING);
  }
}
