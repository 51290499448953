








import store, { ActionsTypes, GettersTypes } from '@/store';
import { SelectBoxLabel } from '@/utils/enum/label';
import { SingleUserDataResponse, TenantUserAppendToList, TenantUserList } from '@qmu/common/dto/userDataDto';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class UserList extends Vue {
  @Prop({ default: false }) isClear!: boolean;
  @Prop({ default: '' }) deletedUserName!: string;
  @Prop({ default: SelectBoxLabel.UserNameOrUserID }) label!: SelectBoxLabel;

  selectedUser = '';

  get reactiveDeletedUserName() {
    return this.deletedUserName;
  }

  set reactiveDeletedUserName(value: string) {
    this.deletedUserName = value;
  }

  @Watch('deletedUserName')
  removedEntryOfDeletedUser() {
    if (!this.reactiveDeletedUserName) return;
    const selectedTenant = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
    const allTenantUserList = store.getters[GettersTypes.GET_TENANT_USER_LIST] as TenantUserList;
    const selectedTenantUsers = allTenantUserList[selectedTenant];

    if(!selectedTenantUsers) return;

    const filterUsers = selectedTenantUsers.filter(user => user.userName !== this.reactiveDeletedUserName);
    const filterTenantUserList: TenantUserAppendToList = { key: selectedTenant, data: filterUsers };
    store.dispatch(ActionsTypes.SET_TENANT_USER_LIST, filterTenantUserList);

    this.setUserList(filterUsers)
  }

  @Watch('isClear')
  onDataChanged() {
    this.selectedUser = '';
  }

  usersData: string[] = [];

  get userList() {
    const selectedTenant = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
    const allTenantUserList = store.getters[GettersTypes.GET_TENANT_USER_LIST] as TenantUserList;

    this.setUserList(allTenantUserList[selectedTenant])

    return this.usersData;
  }

  changeSelected() {
    this.$emit('triggerSelectedUser', this.selectedUser);
  }

  sortList(list: string[]) {
    return list.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }


  getNames(users:SingleUserDataResponse[]):string[]{
    if(!users || !users.length) return[]

    const names:string[] = []
    users.forEach(user=>{
      if(user.lastName) names.push(user.lastName)
    })
    return names
  }

  getUserDropDownInfo(users:SingleUserDataResponse[]):{
    emails: string[];
    userIds: string[];
    userNames: string[];
}{
    const emails:string[] = []
    const userIds:string[] = []
    const userNames:string[] = []
    if(!users || !users.length) return {emails,userIds,userNames}

    users.forEach(user => {
      if(user.email) emails.push(user.email);
      if(user.id) userIds.push(user.id);
      if(user.userName) userNames.push(user.userName);
    })

    return {emails,userIds,userNames}

  }

  setUserList(selectedTenantUsers:SingleUserDataResponse[]){
    if(this.label === SelectBoxLabel.LAST_NAME){
      this.usersData = [...this.sortList(this.getNames(selectedTenantUsers))];
    }else{
      const {emails,userIds,userNames} = this.getUserDropDownInfo(selectedTenantUsers)
      this.usersData = [...this.sortList(emails), ...this.sortList(userNames), ...this.sortList(userIds)];
    }
  }
}
